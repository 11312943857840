import { useTranslation } from "react-i18next";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import TextField from "components/TextField";
import DateInput from "components/DateInput";
import dayjs from "dayjs";
import Stack from "components/Stack";
import BasicButton from "components/BasicButton";
import { colors } from "theme";
import {
  dependentCreateAsSpecialist,
  dependentUpdateAsSpecialist,
} from "entities/Dependent/sdk";

interface IProps {
  familyId?: number;
  onSubmit: () => Promise<void>;
  // Rightside drawer has close animation that keeps the form open until the animation is done.
  submitDisabled: boolean;
  initialData?: {
    id: number;
    first_name?: string;
    last_name?: string;
    date_of_birth?: string;
  };
}

interface IDependentFormValues {
  first_name: string;
  last_name: string;
  date_of_birth: string;
}
const DependentForm = ({
  onSubmit,
  initialData,
  familyId,
  submitDisabled,
}: IProps) => {
  const { t } = useTranslation();
  const isCreating = !initialData;

  const { control, handleSubmit, formState, setError } =
    useForm<IDependentFormValues>({
      defaultValues: {
        first_name: initialData?.first_name,
        last_name: initialData?.last_name,
        date_of_birth: initialData?.date_of_birth
          ? dayjs(initialData?.date_of_birth).format("MM/DD/YYYY")
          : undefined,
      },
    });

  const onSave: SubmitHandler<IDependentFormValues> = async (data) => {
    const formattedBirthDate = data.date_of_birth
      ? dayjs(data.date_of_birth, "MM/DD/YYYY").format("YYYY-MM-DD")
      : null;
    const submitData = { ...data, date_of_birth: formattedBirthDate };

    if (isCreating && familyId) {
      return dependentCreateAsSpecialist({
        familyId,
        data: submitData,
      })
        .then(onSubmit)
        .catch((errors) => {
          if (errors) {
            errors.setFormErrors && errors.setFormErrors(setError);
          }
        });
    } else if (!isCreating) {
      return dependentUpdateAsSpecialist({
        dependentId: initialData.id,
        data: submitData,
      })
        .then(onSubmit)
        .catch((errors) => {
          if (errors) {
            errors.setFormErrors && errors.setFormErrors(setError);
          }
        });
    }
  };

  return (
    <Stack sx={{ height: "100%" }} justifyContent="space-between">
      <Stack>
        <Controller
          name="first_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("firstName")}
              fullWidth
              required={false}
              margin="normal"
              error={!!formState.errors.first_name}
              helperText={formState.errors.first_name?.message}
            />
          )}
        />
        <Controller
          name="last_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("lastName")}
              fullWidth
              margin="normal"
              required={false}
              error={!!formState.errors.last_name}
              helperText={formState.errors.last_name?.message}
            />
          )}
        />
        <Controller
          name="date_of_birth"
          control={control}
          render={({ field }) => (
            <DateInput
              {...field}
              control={control}
              label={t("dateOfBirth")}
              required={false}
              error={!!formState.errors.last_name}
              helperText={formState.errors.last_name?.message}
            />
          )}
        />
      </Stack>
      <Stack
        sx={{
          borderTop: "1px solid",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
            mb: "20px",
          }}
        >
          <BasicButton
            label="Save"
            onClick={handleSubmit((data) => onSave(data))}
            isDisabled={formState.isSubmitting || submitDisabled}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DependentForm;
