import Box from "components/Box";
import IconButton from "components/IconButton";
import Text from "components/Text";
import Stack from "components/Stack";

import { EditIcon } from "icons";
import { colors } from "theme";
import dayjs from "dayjs";
import { useState } from "react";
import EditPanel from "entities/FamilyEdit/components";
import DependentForm from "../FormAsSpecialist";
import { useTranslation } from "react-i18next";
import { useFamilyDetail } from "entities/FamilySpecialist/sdk";

type IProps = {
  familyId: number;
  dependent: {
    id: number;
    first_name: string;
    last_name: string;
    date_of_birth: string;
  };
};

const DependentListItem = ({ familyId, dependent }: IProps) => {
  const { t } = useTranslation();
  const { mutate: refetchFamily } = useFamilyDetail(familyId);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: colors.outline,
        borderRadius: "8px",
        padding: "16px",
        marginBottom: "16px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
      }}
    >
      <Stack gap="5px">
        <Text variant="titleMedium">
          {(dependent.first_name || dependent.last_name) &&
            `${dependent.first_name} ${dependent.last_name}`}

          {!dependent.first_name && !dependent.last_name && "No Dependent Name"}
        </Text>
        <Text variant="bodyMedium">
          DOB:{" "}
          {dependent.date_of_birth &&
            dayjs(dependent.date_of_birth).format("MM/DD/YYYY")}
        </Text>
      </Stack>
      <IconButton
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          height: "40px",
          width: "40px",
        }}
        onClick={() => setIsEditing(true)}
      >
        <EditIcon />
      </IconButton>
      <EditPanel
        open={isEditing}
        onClose={() => setIsEditing(false)}
        title={t("dependentInformation")}
      >
        <DependentForm
          initialData={dependent}
          submitDisabled={!isEditing}
          onSubmit={async () => {
            await refetchFamily();
            setIsEditing(false);
          }}
        />
      </EditPanel>
    </Box>
  );
};

export default DependentListItem;
