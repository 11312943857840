import { useState } from "react";
import { useTranslation } from "react-i18next";

import AddButton from "components/AddButton";
import Stack from "components/Stack";
import EditPanel from "entities/FamilyEdit/components/index";
import DependentListItem from "entities/Dependent/components/ListItem";
import DependentForm from "entities/Dependent/components/FormAsSpecialist";
import { useFamilyDetail } from "entities/FamilySpecialist/sdk";

interface IProps {
  familyId: number;
}

const DependentsList = ({ familyId }: IProps) => {
  const { t } = useTranslation();
  const { data: family, mutate: refetchFamily } = useFamilyDetail(familyId);
  const [isAdding, setIsAdding] = useState(false);

  if (!family) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <EditPanel
        open={isAdding}
        onClose={() => setIsAdding(false)}
        title={t("addDependent")}
      >
        <DependentForm
          submitDisabled={!isAdding}
          familyId={familyId}
          onSubmit={async () => {
            await refetchFamily();
            setIsAdding(false);
          }}
        />
      </EditPanel>
      {family.dependents.map((dependent) => (
        <DependentListItem
          key={dependent.id}
          dependent={dependent}
          familyId={family.id}
        />
      ))}
      <Stack alignItems="center" style={{ marginBottom: "20px" }}>
        <AddButton
          label={t("addDependent")}
          onClick={() => setIsAdding(true)}
        />
      </Stack>
    </Stack>
  );
};

export default DependentsList;
