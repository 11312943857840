import { useState } from "react";

import { Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AppBar from "components/AppBar";
import Box from "components/Box";
import Text from "components/Text";
import Stack from "components/Stack";
import IconButton from "components/IconButton";
import useMediaQuery from "components/useMediaQuery";
import List, {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "components/List";
import Drawer from "components/Drawer";
import Link from "components/Link";
import Button from "components/Button";
import Tooltip from "components/Tooltip";
import FlagLanguageSelector from "components/FlagLanguageSelector";
import { CircularProgress } from "components/Loading";
import CloseDialog from "components/CloseDialog";

import {
  ArrowLeftIcon,
  BurgerMenuIcon,
  CalendarUnfilledIcon,
  // DocumentsIcon,
  FamiliesIcon,
  HelpIcon,
  HomeIcon,
  MessagesIcon,
  // ProfileIcon,
  WhitePlusIcon,
  CloseIcon,
  // HomeFilledIcon,
  FamiliesFilledIcon,
  MessagesFilledIcon,
  AdminIcon,
  AdminFilledIcon,
} from "icons";

import LogoSmallScreen from "assets/logo-navigation.svg?react";
import Logo from "assets/logo-icon.svg?react";

import { colors } from "theme";

import { URLS } from "config/urls";

import { getTitle } from "utils/titles";

import { logout, useSignedUser } from "entities/Auth/sdk";

const USER_TYPE = {
  family_member: "family_member",
  family_specialist: "family_specialist",
} as const;

const Layout = () => {
  const matches = useMediaQuery("(min-width:600px)");
  const { t } = useTranslation();
  const { data: user } = useSignedUser();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const getProgress = () => {
    switch (location.pathname) {
      case URLS.FAMILY_INFORMATION_OVERVIEW:
        return 10;
      case URLS.FAMILY_INFORMATION_ABOUT_YOU:
        return 20;
      case URLS.FAMILY_INFORMATION_YOUR_STATUS:
        return 30;
      case URLS.FAMILY_INFORMATION_PARENTS:
        return 40;
      case URLS.FAMILY_INFORMATION_ADD_PARENT:
        return 40;
      case URLS.FAMILY_INFORMATION_MAILING_ADDRESS:
        return 50;
      case URLS.FAMILY_INFORMATION_CHILDREN:
        return 60;
      case URLS.FAMILY_INFORMATION_ADD_CHILD:
        return 60;
      case URLS.FAMILY_INFORMATION_INCOME_VERIFICATION:
        return 70;
      case URLS.FAMILY_INFORMATION_HOUSEHOLD_DEFINITIONS:
        return 70;
      case URLS.FAMILY_INFORMATION_SPECIAL_CIRCUMSTANCES:
        return 80;
      case URLS.FAMILY_INFORMATION_ADDITIONAL_INFORMATION:
        return 90;
      case URLS.FAMILY_INFORMATION_COMPLETE:
        return 100;
      default:
        return 0;
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    if (location.pathname === URLS.CHILD_CARE_NEEDS_DESCRIPTION) {
      navigate(URLS.INITIAL);
    } else {
      navigate(-1);
    }
  };

  const showNavigation = () => {
    return (
      location.pathname === URLS.HOME ||
      location.pathname === URLS.FAMILY_SPECIALIST_HOME ||
      location.pathname === URLS.FAMILY_SPECIALIST_FAMILY_LIST ||
      location.pathname === URLS.FAMILY_SPECIALIST_MESSAGES_LIST ||
      location.pathname.includes(
        URLS.FAMILY_SPECIALIST_FAMILY_DETAIL.split(":")[0]
      ) ||
      location.pathname === URLS.FAMILY_SPECIALIST_ADMIN
    );
  };

  const isFamilyInformation =
    window.location.href.includes("family-information");

  const showLargeScreenContaner = isFamilyInformation && matches;

  const isDocuments = window.location.href.includes("documents");
  const [showConfirm, setShowConfirm] = useState(false);

  const handleCloseDialogClick = () => {
    setShowConfirm(true);
  };

  const handleConfirmDialogClose = () => {
    setShowConfirm(false);

    navigate(URLS.FAMILY_SPECIALIST_FAMILY_LIST);
  };

  const handleCancelDialogClose = () => {
    setShowConfirm(false);
  };

  const isThankYouPage = location.pathname === URLS.THANK_YOU;

  const title = getTitle(location.pathname);

  const handleLogout = async () => {
    logout().then(() => {
      navigate(URLS.INITIAL);
    });
  };

  if (!user) {
    return (
      <div
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const user_type = user.user_type.toLowerCase();

  if (
    location.pathname.includes("/family-specialist/family-information/family/")
  ) {
    return <Outlet />;
  }

  return (
    <>
      {(!matches || !showNavigation()) && (
        <AppBar
          sx={{
            paddingLeft: showLargeScreenContaner ? "25px" : 0,
            height: "64px",
            backgroundColor: isDocuments
              ? colors.surfaceContainerLow
              : colors.surface,
            justifyContent: "center",
            boxShadow: "none",
            position: showLargeScreenContaner ? "absolute" : "fixed",
            top: showLargeScreenContaner ? "25px" : 0,
            margin: showLargeScreenContaner ? "0 25px" : 0,
          }}
        >
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              marginLeft: matches ? 0 : "25px",
              marginRight: matches ? 0 : "25px",
              borderBottom: isFamilyInformation
                ? "none"
                : `2px solid ${colors.outlineVariant}`,
              height: "100%",
            }}
            spacing={matches ? 0 : 6}
          >
            <Stack
              sx={{
                flex: 1,
                marginLeft: matches ? "35px" : "0px",
              }}
              direction="row"
              alignItems="center"
            >
              {!showNavigation() && !isThankYouPage && !isDocuments && (
                <IconButton onClick={goBack} sx={{ mr: "15px" }}>
                  <ArrowLeftIcon style={{ fill: colors.onSurfaceVariant }} />
                </IconButton>
              )}
              {!matches && showNavigation() && (
                <IconButton onClick={() => setDrawerOpen(true)}>
                  <BurgerMenuIcon style={{ width: "36px", height: "36px" }} />
                </IconButton>
              )}
              <Text variant="titleLarge" color={colors.onSurfaceVariant}>
                {title}
              </Text>
            </Stack>

            <Stack>
              {!isFamilyInformation && !isThankYouPage && (
                <FlagLanguageSelector />
              )}
              {isThankYouPage && (
                <IconButton
                  onClick={() => {
                    user_type === USER_TYPE["family_specialist"]
                      ? navigate(URLS.FAMILY_SPECIALIST_FAMILY_LIST)
                      : navigate(URLS.HOME);
                  }}
                >
                  <CloseIcon style={{ fill: colors.onSurfaceVariant }} />
                </IconButton>
              )}
              {isDocuments && user_type === USER_TYPE["family_specialist"] && (
                <IconButton size="small" onClick={handleCloseDialogClick}>
                  <CloseIcon style={{ fill: colors.onSurfaceVariant }} />
                </IconButton>
              )}
            </Stack>
          </Stack>
          {isFamilyInformation && !isDocuments && (
            <Stack sx={{ padding: "0 25px" }}>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: 4,
                  backgroundColor: colors.primary,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    height: "100%",
                    width: "10px",
                    left: `${getProgress()}%`,
                    backgroundColor: colors.surface,
                  }}
                />
              </Box>
            </Stack>
          )}
        </AppBar>
      )}
      {showNavigation() && (
        <Drawer
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              backgroundColor:
                user_type === USER_TYPE["family_member"]
                  ? colors.surfaceContainerHigh
                  : colors.surfaceContainerLow,
              paddingTop: "30px",
              paddingBottom: "30px",
              width:
                user_type === USER_TYPE["family_specialist"]
                  ? "auto"
                  : matches
                    ? "130px"
                    : "230px",
              borderRight: 0,
              justifyContent: "space-between",
              alignItems: "center",
            },
          }}
          anchor="left"
          open={drawerOpen}
          variant={matches ? "permanent" : "temporary"}
          onClose={() => setDrawerOpen(false)}
        >
          <Stack sx={{ width: "100%" }}>
            <Stack sx={{ mx: "16px", cursor: "pointer" }} alignItems="center">
              <Link
                to={
                  user_type === USER_TYPE["family_specialist"]
                    ? URLS.FAMILY_SPECIALIST_FAMILY_LIST
                    : URLS.HOME
                }
              >
                {matches ? <Logo /> : <LogoSmallScreen />}
              </Link>
            </Stack>
            {user_type === USER_TYPE["family_specialist"] && (
              <Stack alignItems="center" sx={{ mb: "10px", mt: "15px" }}>
                <Tooltip title={t("newFamilyReferral")} placement="right">
                  <IconButton
                    component="button"
                    sx={{
                      width: "54px",
                      height: "54px",
                      backgroundColor: colors.primary,
                      borderRadius: "16px",
                      "&:hover": {
                        backgroundColor: colors.primary,
                      },
                    }}
                    onClick={() =>
                      navigate(URLS.FAMILY_SPECIALIST_AUTHORIZATION)
                    }
                  >
                    <WhitePlusIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
            {user_type === USER_TYPE["family_member"] && (
              <List sx={{ width: "100%", padding: 0, marginTop: "20px" }}>
                <Link to={URLS.HOME}>
                  <ListItem
                    sx={{
                      height: "72px",
                      paddingTop: "20px",
                      paddingLeft: 0,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        flexDirection: matches ? "column" : "row",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ListItemIcon
                        sx={{ minWidth: "40px", justifyContent: "center" }}
                      >
                        <HomeIcon height="24px" width="24px" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          "& .MuiTypography-root": {
                            color: colors.onSurfaceVariant,
                          },
                        }}
                      >
                        <Text variant="titleSmall">{t("home")}</Text>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Link>
                {/* <Link to={URLS.HOME}>
                  <ListItem
                    sx={{
                      height: "72px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      paddingLeft: 0,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        flexDirection: matches ? "column" : "row",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ListItemIcon
                        sx={{ minWidth: "40px", justifyContent: "center" }}
                      >
                        <ProfileIcon height="24px" width="24px" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          "& .MuiTypography-root": {
                            color: colors.onSurfaceVariant,
                          },
                        }}
                      >
                        <Text variant="titleSmall">{t("account")}</Text>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Link> */}

                <Link
                  to={"https://booknow.appointment-plus.com/180n6yhv/"}
                  external
                  target="_blank"
                >
                  <ListItem
                    sx={{
                      height: "72px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      paddingLeft: 0,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        flexDirection: matches ? "column" : "row",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ListItemIcon
                        sx={{ minWidth: "40px", justifyContent: "center" }}
                      >
                        <CalendarUnfilledIcon />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          "& .MuiTypography-root": {
                            color: colors.onSurfaceVariant,
                          },
                        }}
                      >
                        <Text variant="titleSmall">{t("appointment")}</Text>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Link>
                <Link to={URLS.GET_HELP}>
                  <ListItem
                    sx={{
                      height: "72px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      paddingLeft: 0,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        flexDirection: matches ? "column" : "row",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ListItemIcon
                        sx={{ minWidth: "40px", justifyContent: "center" }}
                      >
                        <HelpIcon />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          "& .MuiTypography-root": {
                            color: colors.onSurfaceVariant,
                          },
                        }}
                      >
                        <Text variant="titleSmall">{t("needHelp")}</Text>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Link>
              </List>
            )}
            {user_type === USER_TYPE["family_specialist"] && (
              <List sx={{ width: "100%", padding: 0, marginTop: "20px" }}>
                {/* <Link to={URLS.FAMILY_SPECIALIST_HOME}>
                  <ListItem
                    sx={{
                      height: "62px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      paddingLeft: 0,
                      paddingRight: 0,
                      marginBottom: "10px",
                    }}
                  >
                    <ListItemButton sx={{ flexDirection: "column" }}>
                      <ListItemIcon
                        sx={{
                          minWidth: "auto",
                          backgroundColor:
                            location.pathname === URLS.FAMILY_SPECIALIST_HOME
                              ? colors.secondaryContainer
                              : "inherit",
                          borderRadius: "20px",
                          padding: "5px 15px",
                        }}
                      >
                        {location.pathname === URLS.FAMILY_SPECIALIST_HOME ? (
                          <HomeFilledIcon />
                        ) : (
                          <HomeIcon />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          "& .MuiTypography-root": {
                            color:
                              location.pathname === URLS.FAMILY_SPECIALIST_HOME
                                ? colors.onSurface
                                : colors.onSurfaceVariant,
                            fontWeight:
                              location.pathname === URLS.FAMILY_SPECIALIST_HOME
                                ? "bold"
                                : "inherit",
                          },
                        }}
                      >
                        <Text variant="titleSmall">{t("home")}</Text>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Link> */}

                <Link to={URLS.FAMILY_SPECIALIST_FAMILY_LIST}>
                  <ListItem
                    sx={{
                      height: "62px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      paddingLeft: 0,
                      paddingRight: 0,
                      marginBottom: "10px",
                    }}
                  >
                    <ListItemButton sx={{ flexDirection: "column" }}>
                      <ListItemIcon
                        sx={{
                          minWidth: "auto",
                          backgroundColor:
                            location.pathname ===
                            URLS.FAMILY_SPECIALIST_FAMILY_LIST
                              ? colors.secondaryContainer
                              : "inherit",
                          borderRadius: "20px",
                          padding: "5px 15px",
                        }}
                      >
                        {location.pathname ===
                        URLS.FAMILY_SPECIALIST_FAMILY_LIST ? (
                          <FamiliesFilledIcon />
                        ) : (
                          <FamiliesIcon />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          "& .MuiTypography-root": {
                            color: colors.onSurfaceVariant,
                          },
                        }}
                      >
                        <Text variant="titleSmall">{t("families")}</Text>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Link>
                <Link to={URLS.FAMILY_SPECIALIST_MESSAGES_LIST}>
                  <ListItem
                    sx={{
                      height: "62px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      paddingLeft: 0,
                      paddingRight: 0,
                      marginBottom: "10px",
                    }}
                  >
                    <ListItemButton sx={{ flexDirection: "column" }}>
                      <ListItemIcon
                        sx={{
                          minWidth: "auto",
                          backgroundColor:
                            location.pathname ===
                            URLS.FAMILY_SPECIALIST_MESSAGES_LIST
                              ? colors.secondaryContainer
                              : "inherit",
                          borderRadius: "20px",
                          padding: "5px 15px",
                        }}
                      >
                        {location.pathname ===
                        URLS.FAMILY_SPECIALIST_MESSAGES_LIST ? (
                          <MessagesFilledIcon />
                        ) : (
                          <MessagesIcon />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          "& .MuiTypography-root": {
                            color: colors.onSurfaceVariant,
                          },
                        }}
                      >
                        <Text variant="titleSmall">{t("messages")}</Text>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Link>
                <Link to={URLS.FAMILY_SPECIALIST_ADMIN}>
                  <ListItem
                    sx={{
                      height: "62px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      paddingLeft: 0,
                      paddingRight: 0,
                      marginBottom: "10px",
                    }}
                  >
                    <ListItemButton sx={{ flexDirection: "column" }}>
                      <ListItemIcon
                        sx={{
                          minWidth: "auto",
                          backgroundColor:
                            location.pathname === URLS.FAMILY_SPECIALIST_ADMIN
                              ? colors.secondaryContainer
                              : "inherit",
                          borderRadius: "20px",
                          padding: "5px 15px",
                        }}
                      >
                        {location.pathname === URLS.FAMILY_SPECIALIST_ADMIN ? (
                          <AdminFilledIcon />
                        ) : (
                          <AdminIcon />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          "& .MuiTypography-root": {
                            color: colors.onSurfaceVariant,
                          },
                        }}
                      >
                        <Text variant="titleSmall">{t("admin")}</Text>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Link>
                {/*<Link to={URLS.FAMILY_SPECIALIST_HOME} external target="_blank">
                  <ListItem
                    sx={{
                      height: "62px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      paddingLeft: 0,
                      paddingRight: 0,
                      marginBottom: "10px",
                    }}
                  >
                    <ListItemButton sx={{ flexDirection: "column" }}>
                      <ListItemIcon
                        sx={{
                          minWidth: "auto",
                          borderRadius: "20px",
                          padding: "5px 15px",
                        }}
                      >
                        <DocumentsIcon />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          "& .MuiTypography-root": {
                            color: colors.onSurfaceVariant,
                          },
                        }}
                      >
                        <Text variant="titleSmall">{t("documents")}</Text>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Link> */}
              </List>
            )}
          </Stack>
          <Stack alignSelf="flex-start" sx={{ pl: "16px" }}>
            <Button onClick={handleLogout}>
              <Text
                sx={{
                  color: colors.onSurfaceVariant,
                  variant: "titleSmall",
                  textTransform: "none",
                }}
              >
                {t("logout")}
              </Text>
            </Button>
          </Stack>
        </Drawer>
      )}
      {showLargeScreenContaner && (
        <Stack
          sx={{
            minHeightheight: "100vh",
            backgroundColor: colors.surfaceContainerLow,
            padding: "25px",
          }}
        >
          <Box
            sx={{
              backgroundColor: isDocuments
                ? colors.surfaceContainerLow
                : colors.surface,
              height: "100%",
              padding: "70px 25px 20px 25px",
            }}
          >
            <Outlet />
          </Box>
        </Stack>
      )}
      {!showLargeScreenContaner && (
        <Box
          sx={{
            p: matches
              ? user_type
                ? user_type === USER_TYPE["family_specialist"]
                  ? "30px 41px 30px 120px"
                  : showNavigation()
                    ? "30px 41px 30px 170px"
                    : "70px 41px"
                : "90px 25px"
              : "80px 25px 20px 25px",
            backgroundColor:
              user_type === USER_TYPE["family_specialist"]
                ? colors.surfaceContainerLow
                : colors.surface,
          }}
        >
          <Outlet />
        </Box>
      )}
      <CloseDialog
        open={showConfirm}
        onClose={handleCancelDialogClose}
        onConfirm={handleConfirmDialogClose}
      />
    </>
  );
};

export default Layout;
