import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import Divider from "components/Divider";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";
import useMediaQuery from "components/useMediaQuery";

import { ISpecialCircumstances } from "entities/FamilyMember/sdk";
import { colors } from "theme";
import { IFamilyDetail } from "entities/FamilySpecialist/sdk";
import { IFamily } from "entities/Family/sdk";

interface ISpecialCircumstanceFormProps {
  onSave: (data: ISpecialCircumstances) => Promise<void>;
  initialData?: IFamilyDetail | IFamily;
}

const SpecialCircumstanceForm = ({
  onSave,
  initialData,
}: ISpecialCircumstanceFormProps) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");
  const { control, handleSubmit, reset } = useForm<ISpecialCircumstances>({
    defaultValues: {
      family_has_experienced_domestic_violence:
        initialData?.vouchers[0].family_has_experienced_domestic_violence ||
        false,
      family_is_experiencing_homelessness:
        initialData?.vouchers[0].family_is_experiencing_homelessness || false,
      parent_has_a_disability:
        initialData?.vouchers[0].parent_has_a_disability || false,
      parent_is_a_member_of_the_us_military:
        initialData?.vouchers[0].parent_is_a_member_of_the_us_military || false,
      parent_is_an_early_educator:
        initialData?.vouchers[0].parent_is_an_early_educator || false,
      parent_is_receiving_treatment_for_substance_abuse:
        initialData?.vouchers[0]
          .parent_is_receiving_treatment_for_substance_abuse || false,
      teen_parent: initialData?.vouchers[0].teen_parent || false,
      child_only_needs_coverage_during_school_closures:
        initialData?.vouchers[0]
          .child_only_needs_coverage_during_school_closures || false,
    },
  });

  useEffect(() => {
    if (initialData) {
      reset({
        family_has_experienced_domestic_violence:
          initialData.vouchers[0].family_has_experienced_domestic_violence ||
          false,
        family_is_experiencing_homelessness:
          initialData.vouchers[0].family_is_experiencing_homelessness || false,
        parent_has_a_disability:
          initialData.vouchers[0].parent_has_a_disability || false,
        parent_is_a_member_of_the_us_military:
          initialData.vouchers[0].parent_is_a_member_of_the_us_military ||
          false,
        parent_is_an_early_educator:
          initialData.vouchers[0].parent_is_an_early_educator || false,
        parent_is_receiving_treatment_for_substance_abuse:
          initialData.vouchers[0]
            .parent_is_receiving_treatment_for_substance_abuse || false,
        teen_parent: initialData.vouchers[0].teen_parent || false,
        child_only_needs_coverage_during_school_closures:
          initialData.vouchers[0]
            .child_only_needs_coverage_during_school_closures || false,
      });
    }
  }, [initialData, reset]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (data: ISpecialCircumstances) => {
    setIsSubmitting(true);
    onSave(data)
      .catch(() => {
        toast.error(t("errorSavingData"));
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Stack alignItems={"center"} sx={{ height: "100%", flex: 1 }}>
      <Text variant="titleMedium" sx={{ my: 3 }}>
        {t("priorityNotice")}
      </Text>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <Stack
          sx={{ height: "100%", flex: 1 }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack sx={{ width: matches ? "400px" : "100%" }}>
            <Controller
              name="family_has_experienced_domestic_violence"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t("domesticViolence")}
                />
              )}
            />
            <Divider sx={{ my: "10px" }} />
            <Controller
              name="family_is_experiencing_homelessness"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t("homelessness")}
                />
              )}
            />
            <Divider sx={{ my: "10px" }} />
            <Controller
              name="parent_has_a_disability"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t("parentDisability")}
                />
              )}
            />
            <Divider sx={{ my: "10px" }} />
            <Controller
              name="parent_is_a_member_of_the_us_military"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t("militaryMember")}
                />
              )}
            />
            <Divider sx={{ my: "10px" }} />
            <Controller
              name="parent_is_an_early_educator"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t("earlyEducator")}
                />
              )}
            />
            <Divider sx={{ my: "10px" }} />
            <Controller
              name="parent_is_receiving_treatment_for_substance_abuse"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t("substanceAbuseTreatment")}
                />
              )}
            />
            <Divider sx={{ my: "10px" }} />
            <Controller
              name="teen_parent"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t("teenParent")}
                />
              )}
            />
            <Divider sx={{ my: "10px" }} />
            <Controller
              name="child_only_needs_coverage_during_school_closures"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t("schoolClosureCoverage")}
                />
              )}
            />
            <Divider sx={{ my: "10px" }} />
          </Stack>
          <Stack
            alignItems={matches ? "center" : "flex-end"}
            sx={{
              width: "100%",
              borderTop: matches ? "1px solid" : "none",
              borderColor: colors.outline,
            }}
          >
            <Stack sx={{ mt: "30px", width: "100%" }} alignItems="center">
              <BasicButton
                buttonType="submit"
                label={t("next")}
                isDisabled={isSubmitting}
              />
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default SpecialCircumstanceForm;
