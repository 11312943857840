import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Container from "components/Container";
import FullPageDialog from "components/FullPageDialog";
import PlacementForm from "entities/Placement/components/Form";

import {
  IPlacement,
  placementCreateInIncomeEligible,
} from "entities/Placement/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const PlacementAddPage = () => {
  const { t } = useTranslation();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();
  const navigate = useNavigate();

  if (!voucherIdParam) {
    return null;
  }

  const handleSave = (values: Partial<IPlacement>) =>
    placementCreateInIncomeEligible({
      voucherId: parseInt(voucherIdParam),
      data: values,
    }).then(() => {
      navigate(
        reverse(URLS.VERIFY_INCOME_PLACEMENT_LIST, {
          voucherId: voucherIdParam,
        })
      );
    });

  return (
    <FullPageDialog
      title={t("placements")}
      backUrl={reverse(URLS.VERIFY_INCOME_PLACEMENT_LIST, {
        voucherId: voucherIdParam,
      })}
      closeUrl={URLS.HOME}
    >
      <Container maxWidth="sm" sx={{ paddingTop: 2 }}>
        <PlacementForm
          onCancel={() => {
            navigate(
              reverse(URLS.VERIFY_INCOME_PLACEMENT_LIST, {
                voucherId: voucherIdParam,
              })
            );
          }}
          initialVoucherId={parseInt(voucherIdParam)}
          onSave={handleSave}
        />
      </Container>
    </FullPageDialog>
  );
};

export default PlacementAddPage;
