import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FullPageDialog from "components/FullPageDialog";
import Container from "components/Container";
import Stack from "components/Stack";
import { reverse } from "utils/urls";
import { URLS } from "config/urls";
import { useFamilySpecialistChildren } from "entities/FamilyEdit/sdk";
import ChildrenList from "entities/Child/components/ChildrenListForm";
import { childData } from "entities/FamilyMember/sdk";

const FamilySpecialistChildren = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { familyId } = useParams<{ familyId: string }>();
  const familyIdNumber = familyId ? parseInt(familyId) : undefined;
  if (!familyIdNumber) {
    return null;
  }
  const { data: fetchedChildrenData, error } =
    useFamilySpecialistChildren(familyIdNumber);
  const [currentChildrenData, setCurrentChildrenData] = useState<childData[]>(
    []
  );

  useEffect(() => {
    if (fetchedChildrenData) {
      const preselectedChildren = fetchedChildrenData.map((child) => {
        const today = new Date();
        const birthDate = child.date_of_birth
          ? new Date(child.date_of_birth)
          : null;
        let age = birthDate ? today.getFullYear() - birthDate.getFullYear() : 0;
        if (
          birthDate &&
          (today.getMonth() < birthDate.getMonth() ||
            (today.getMonth() === birthDate.getMonth() &&
              today.getDate() < birthDate.getDate()))
        ) {
          age--;
        }
        return {
          firstName: child.first_name,
          lastName: child.last_name,
          age,
        };
      });
      setCurrentChildrenData(preselectedChildren);
    }
  }, [fetchedChildrenData]);

  const handleNext = () => {
    navigate(
      reverse(URLS.FAMILY_SPECIALIST_SPECIAL_CIRCUMSTANCES, {
        familyId: familyIdNumber,
      })
    );
  };

  const handleAddChild = () => {
    navigate(
      reverse(URLS.FAMILY_SPECIALIST_ADD_CHILD, { familyId: familyIdNumber })
    );
  };

  return (
    <FullPageDialog
      title={t("children")}
      backUrl={reverse(URLS.FAMILY_SPECIALIST_MAILING_ADDRESS, {
        familyId: familyIdNumber,
      })}
    >
      <Container
        maxWidth="lg"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            flex: 1,
            justifyContent: "space-between",
            padding: 2,
          }}
        >
          {!fetchedChildrenData ? null : (
            <ChildrenList
              childrenData={currentChildrenData}
              onAddChild={handleAddChild}
              onNext={handleNext}
              errorLoadingData={!!error}
            />
          )}
        </Stack>
      </Container>
    </FullPageDialog>
  );
};

export default FamilySpecialistChildren;
