import { useNavigate } from "react-router-dom";

import AdditionalInformationForm, {
  IAdditionalInformationFormData,
} from "entities/Family/components/AdditionalInformationForm";
import { additionalInformationUpdate } from "entities/FamilyMember/sdk";
import { URLS } from "config/urls";

const AdditionalInformation = () => {
  const navigate = useNavigate();

  const handleSave = (data: IAdditionalInformationFormData) => {
    return additionalInformationUpdate(data).then(() => {
      navigate(URLS.FAMILY_INFORMATION_COMPLETE);
    });
  };

  return <AdditionalInformationForm onSave={handleSave} />;
};

export default AdditionalInformation;
