import { BASE_URL, post } from "utils/sdk";

export interface IDependent {
  id: number;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  is_dependent_included_in_voucher: boolean;
}

export const dependentCreateAsSpecialist = ({
  data,
  familyId,
}: {
  familyId: number;
  data: Partial<{
    first_name: string;
    last_name: string;
    date_of_birth: string | null;
  }>;
}) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${familyId}/dependent/create/ `,
    data
  );

export const dependentUpdateAsSpecialist = ({
  dependentId,
  data,
}: {
  dependentId: number;
  data: {
    first_name?: string | null;
    last_name?: string | null;
    date_of_birth?: string | null;
  };
}) =>
  post(
    `${BASE_URL}/api/family-specialists/family/dependent/${dependentId}/update/ `,
    data
  );

export const incomeEligibleDependentCreate = ({
  data,
  voucherId,
}: {
  data: Partial<IDependent>;
  voucherId: number;
}) =>
  post(
    `${BASE_URL}/api/family-members/income-eligible/${voucherId}/dependent/create/ `,
    data
  );

export const incomeEligibleDependentUpdate = ({
  dependentId,
  data,
  voucherId,
}: {
  dependentId: number;
  data: Partial<IDependent>;
  voucherId: number;
}) =>
  post(
    `${BASE_URL}/api/family-members/income-eligible/${voucherId}/dependent/${dependentId}/update/ `,
    data
  );
