import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import TextField from "components/TextField";
import StyledSelect from "components/StyledSelect";
import DateInput from "components/DateInput";

import { useDocumentsOptions } from "entities/Documents/sdk";

import { phoneMask } from "utils/masks";
import { useFormWarnings } from "utils/forms";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";

interface IProps {
  fieldsWithWarnings?: string[];
}

const HomelessReferral = ({ fieldsWithWarnings }: IProps) => {
  const { t } = useTranslation();
  const { childCareStatusOptions, referralSourceOptions } =
    useDocumentsOptions();
  const { control } = useFormContext();
  const { fieldWarningsAndErrors } = useFormWarnings({
    formState: control._formState,
    fieldsWithWarnings,
  });

  return (
    <>
      <Controller
        name="referral_date"
        control={control}
        render={({ field }) => (
          <DateInput
            {...field}
            label={t("referralDate")}
            control={control}
            required={false}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="child_care_status"
        control={control}
        render={({ field }) => (
          <StyledSelect
            {...field}
            label={t("childCareStatus")}
            options={childCareStatusOptions}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="referral_source"
        control={control}
        render={({ field }) => (
          <StyledSelect
            {...field}
            label={t("referralSource")}
            options={referralSourceOptions}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="shelter"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("shelter")}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="shelter_contact"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("shelterContact")}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="shelter_contact_email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("shelterContactEmail")}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="shelter_phone"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("shelterContactPhone")}
            inputRef={(ref) => {
              field.ref(ref);
              phoneMask(ref);
            }}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="ccrr"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("CCRR")}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="is_issued"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label="Voucher Issued"
          />
        )}
      />
 
    </>
  );
};

export default HomelessReferral;
