import { useNavigate } from "react-router-dom";

import SpecialCircumstanceForm from "entities/Family/components/SpecialCircumstanceForm";
import {
  specialCircumstanceUpdate,
  ISpecialCircumstances,
} from "entities/FamilyMember/sdk";
import { URLS } from "config/urls";
import { useMyFamily } from "entities/Family/sdk";

const SpecialCircumstances = () => {
  const navigate = useNavigate();
  const { data: family } = useMyFamily();

  const handleSave = (data: ISpecialCircumstances) => {
    return specialCircumstanceUpdate(data).then(() => {
      navigate(URLS.FAMILY_INFORMATION_ADDITIONAL_INFORMATION);
    });
  };

  return <SpecialCircumstanceForm onSave={handleSave} initialData={family} />;
};

export default SpecialCircumstances;
