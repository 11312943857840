import { useTranslation } from "react-i18next";

import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";
import Chip from "components/Chip";
import IconButton from "components/IconButton";
import Link from "components/Link";

import theme, { colors } from "theme";

import { IFamily, IParentToIncomeEligibles } from "entities/Family/sdk";
import { CheckIcon, EditIcon } from "icons";
import { reverse } from "utils/urls";
import { URLS } from "config/urls";
import { ParsingType } from "entities/Documents/sdk";

interface IncomeSubsectionProps {
  title: string;
  family: IFamily;
  voucherId: string;
  fieldName: keyof IParentToIncomeEligibles;
  docVerificationField: string;
  monthlyValueField: keyof IParentToIncomeEligibles;
  navigateUrl: URLS;
  documentType: ParsingType;
}

const IncomeSubsection = ({
  title,
  family,
  voucherId,
  fieldName,
  docVerificationField,
  monthlyValueField,
  navigateUrl,
  documentType,
}: IncomeSubsectionProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: theme.spacing(2) }}>
      <Stack
        spacing={1}
        direction="row"
        sx={{ alignItems: "center", mt: 3, width: "100%" }}
      >
        <Divider sx={{ flex: 1 }} />
        <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
          {title}
        </Text>
        <Divider sx={{ flex: 1 }} />
      </Stack>
      {family.family_members.map((parent) => {
        const parentIncomeEligible = parent.parent_to_income_eligibles.find(
          (parentIncomeEligible) =>
            parentIncomeEligible.voucher.id.toString() === voucherId
        );
        if (!parentIncomeEligible || !parentIncomeEligible[fieldName])
          return null;

        const monthlyValue = parentIncomeEligible[monthlyValueField] ?? 0;
        const verifiedDocsCount = family.documents.filter(
          (doc) =>
            doc.parent?.id === parent.id &&
            doc.vouchers?.some((v) => v.id.toString() === voucherId) &&
            (doc as any)[docVerificationField] === true
        ).length;

        const unverifiedDocsCount = family.documents.filter(
          (doc) =>
            doc.parent?.id === parent.id &&
            doc.vouchers?.some((v) => v.id.toString() === voucherId) &&
            (doc as any)[docVerificationField] !== true &&
            doc.parsing_types.includes(documentType)
        ).length;

        return (
          <Stack key={parent.id} sx={{ mt: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack>
                <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                  {parent.first_name} {parent.last_name}
                </Text>
                <Text
                  variant="labelMedium"
                  fontWeight="600"
                  color={colors.onSurfaceVariant}
                >
                  ${String(monthlyValue)} / {t("month")}
                </Text>
              </Stack>
              <Link
                to={reverse(navigateUrl, { parentId: parent.id, voucherId })}
              >
                <IconButton sx={{ width: 42, height: 42 }}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Stack>

            {verifiedDocsCount > 0 && (
              <div>
                <Chip
                  icon={<CheckIcon />}
                  label={
                    <Text
                      sx={{
                        fontWeight: "bold",
                        textTransform: "lowercase",
                      }}
                    >
                      {verifiedDocsCount}{" "}
                      {verifiedDocsCount !== 1 ? t("documents") : t("document")}{" "}
                      {t("verified")}
                    </Text>
                  }
                  sx={{ backgroundColor: colors.secondaryContainer }}
                />
              </div>
            )}
            {verifiedDocsCount === 0 && unverifiedDocsCount > 0 && (
              <Text
                variant="labelMedium"
                color={colors.error}
                sx={{ textTransform: "lowercase" }}
              >
                {unverifiedDocsCount}{" "}
                {unverifiedDocsCount !== 1 ? t("documents") : t("document")}{" "}
                {t("unverified")}
              </Text>
            )}

            {verifiedDocsCount === 0 && unverifiedDocsCount === 0 && (
              <Text variant="labelMedium" color={colors.error}>
                {t("missingDocuments")}
              </Text>
            )}
          </Stack>
        );
      })}
    </div>
  );
};

export default IncomeSubsection;
