import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FullPageDialog from "components/FullPageDialog";
import Container from "components/Container";
import Stack from "components/Stack";

import ParentForm from "entities/Parent/components/Form";
import { parentCreateAsSpecialist, IParent } from "entities/Parent/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const ParentAddAsSpecialistPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { familyId: familyIdParam } = useParams<{ familyId: string }>();
  const familyId = parseInt(String(familyIdParam));

  const handleSave = (values: Partial<IParent>) =>
    parentCreateAsSpecialist({ familyId, data: values }).then(() => {
      navigate(reverse(URLS.FAMILY_SPECIALIST_PARENTS, { familyId }));
    });

  return (
    <FullPageDialog
      title={t("addParent")}
      backUrl={reverse(URLS.FAMILY_SPECIALIST_PARENTS, {
        familyId: familyId,
      })}
    >
      <Container
        maxWidth="lg"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            flex: 1,
            justifyContent: "space-between",
            padding: 2,
          }}
        >
          <ParentForm onSave={handleSave} />
        </Stack>
      </Container>
    </FullPageDialog>
  );
};

export default ParentAddAsSpecialistPage;
