import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FullPageDialog from "components/FullPageDialog";
import Container from "components/Container";
import Stack from "components/Stack";

import AdditionalInformationForm, {
  IAdditionalInformationFormData,
} from "entities/Family/components/AdditionalInformationForm";
import { familySpecialistAdditionalInformationUpdate } from "entities/FamilyEdit/sdk";
import { reverse } from "utils/urls";
import { URLS } from "config/urls";

const FamilySpecialistIncomeEligibleAdditionalInformation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { familyId } = useParams<{ familyId: string }>();
  const familyIdNumber = familyId ? parseInt(familyId) : undefined;
  if (!familyIdNumber) return null;

  const handleSave = (data: IAdditionalInformationFormData) => {
    return familySpecialistAdditionalInformationUpdate(
      familyIdNumber,
      data
    ).then(() => {
      navigate(
        reverse(URLS.FAMILY_SPECIALIST_COMPLETE, { familyId: familyIdNumber })
      );
    });
  };

  return (
    <FullPageDialog
      title={t("additionalInformation")}
      backUrl={reverse(URLS.FAMILY_SPECIALIST_INCOME_ELIGIBLE_CHILDREN, {
        familyId: familyIdNumber,
      })}
    >
      <Container
        maxWidth="lg"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Stack sx={{ flex: 1, justifyContent: "space-between", padding: 2 }}>
          <AdditionalInformationForm onSave={handleSave} />
        </Stack>
      </Container>
    </FullPageDialog>
  );
};

export default FamilySpecialistIncomeEligibleAdditionalInformation;
