import { useNavigate } from "react-router-dom";

import MailingAddressForm from "entities/Family/components/MailingAddressForm";

import { URLS } from "config/urls";

import { addressUpdate, IAddressUpdate } from "entities/FamilyMember/sdk";
import { useMyFamily } from "entities/Family/sdk";

const MailingAddress = () => {
  const navigate = useNavigate();
  const { data: family } = useMyFamily();

  const handleSave = (data: IAddressUpdate) => {
    return addressUpdate(data).then(() => {
      navigate(URLS.FAMILY_INFORMATION_CHILDREN);
    });
  };

  return (
    <MailingAddressForm
      onSave={handleSave}
      hasValidation={true}
      initialData={family}
    />
  );
};

export default MailingAddress;
