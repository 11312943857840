import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import TextField from "components/TextField";
import StyledSelect from "components/StyledSelect";
import DateInput from "components/DateInput";

import { useDocumentsOptions } from "entities/Documents/sdk";
import { useFormWarnings } from "utils/forms";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";

interface IProps {
  fieldsWithWarnings?: string[];
}

const DTAReferral = ({ fieldsWithWarnings }: IProps) => {
  const { t } = useTranslation();
  const { childCareStatusOptions, serviceNeedOptions, dtaProgramOptions } =
    useDocumentsOptions();
  const { control } = useFormContext();

  const { fieldWarningsAndErrors } = useFormWarnings({
    formState: control._formState,
    fieldsWithWarnings,
  });

  return (
    <>
      <Controller
        name="referral_date"
        control={control}
        render={({ field }) => (
          <DateInput
            {...field}
            label={t("referralDate")}
            control={control}
            required={false}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="child_care_status"
        control={control}
        render={({ field }) => (
          <StyledSelect
            {...field}
            label={t("childCareStatus")}
            options={childCareStatusOptions}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="dta_referral_number"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("referralNumber")}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="ccrr"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("CCRR")}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="dta_program"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("dtaProgram")}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="dta_referral_type"
        control={control}
        render={({ field }) => (
          <StyledSelect
            {...field}
            label={t("childCareReferralType")}
            options={dtaProgramOptions}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="tafdc_closing_date"
        control={control}
        render={({ field }) => (
          <DateInput
            {...field}
            label={t("tafdcBenefitClosingDate")}
            control={control}
            required={false}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="issuing_location"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("localDtaOffice")}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="service_need"
        control={control}
        render={({ field }) => (
          <StyledSelect
            {...field}
            label={t("serviceNeed")}
            options={serviceNeedOptions}
            {...fieldWarningsAndErrors(field)}
          />
        )}
      />
      <Controller
        name="is_issued"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label="Voucher Issued"
          />
        )}
      />
    </>
  );
};

export default DTAReferral;
