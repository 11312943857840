import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import Stack from "components/Stack";
import BasicButton from "components/BasicButton";
import FormControl from "components/FormControl";
import Radio, { RadioGroup } from "components/Radio";
import FormControlLabel from "components/FormControlLabel";
import TextField from "components/TextField";
import useMediaQuery from "components/useMediaQuery";
import FormHelperText from "components/FormHelperText";

import { colors } from "theme";

import { IEmploymentStatusUpdate } from "entities/FamilyMember/sdk";
import { IFamilyDetail } from "entities/FamilySpecialist/sdk";
import { IFamily } from "entities/Family/sdk";

interface IYourStatusFormProps {
  onSave: (data: IEmploymentStatusUpdate) => Promise<void>;
  hasValidation?: boolean;
  initialData?: IFamilyDetail | IFamily;
}

const YourStatusForm = ({
  onSave,
  hasValidation = false,
  initialData,
}: IYourStatusFormProps) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [employmentStatusError, setEmploymentStatusError] = useState<
    string | null
  >(null);
  const [employerNameError, setEmployerNameError] = useState<string | null>(
    null
  );
  const [otherStatusDescriptionError, setOtherStatusDescriptionError] =
    useState<string | null>(null);
  const matches = useMediaQuery("(min-width:600px)");

  const { control, watch, reset } = useForm<IEmploymentStatusUpdate>({
    defaultValues: {
      employment_status: initialData?.creator.employment_status || undefined,
      employer_name: initialData?.creator.employer_name || "",
      other_status_description:
        initialData?.creator.other_status_description || "",
    },
  });

  useEffect(() => {
    if (initialData) {
      reset({
        employment_status: initialData.creator.employment_status || undefined,
        employer_name: initialData.creator.employer_name || "",
        other_status_description:
          initialData.creator.other_status_description || "",
      });
    }
  }, [initialData, reset]);

  const employmentStatus = watch("employment_status");
  const employerName = watch("employer_name");
  const otherStatusDescription = watch("other_status_description");

  const handleNextClick = () => {
    let valid = true;

    if (hasValidation) {
      if (!employmentStatus) {
        setEmploymentStatusError(t("optionSelectRequired"));
        valid = false;
      } else {
        setEmploymentStatusError(null);
      }

      if (employmentStatus === "EMPLOYED" && !employerName) {
        setEmployerNameError(t("fieldRequired"));
        valid = false;
      } else {
        setEmployerNameError(null);
      }

      if (employmentStatus === "OTHER" && !otherStatusDescription) {
        setOtherStatusDescriptionError(t("fieldRequired"));
        valid = false;
      } else {
        setOtherStatusDescriptionError(null);
      }
    }

    if (valid) {
      setIsSubmitting(true);
      const data: IEmploymentStatusUpdate = {
        employment_status: employmentStatus,
        employer_name:
          employmentStatus === "EMPLOYED" ? employerName : undefined,
        other_status_description:
          employmentStatus === "OTHER" ? otherStatusDescription : undefined,
      };

      onSave(data)
        .catch(() => {
          toast.error(t("errorSavingData"));
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <>
      <Stack alignItems="center">
        <form style={{ width: matches ? "400px" : "100%" }}>
          <FormControl
            component="fieldset"
            margin="normal"
            sx={{ width: "100%" }}
            error={!!employmentStatusError}
          >
            <Controller
              name="employment_status"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  row
                  aria-label="employment status"
                  sx={{ flexDirection: "column" }}
                >
                  <FormControlLabel
                    value="EMPLOYED"
                    control={<Radio />}
                    label={t("iAmEmployed")}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: colors.outlineVariant,
                      marginRight: 0,
                      padding: "10px 0",
                    }}
                  />
                  <FormControlLabel
                    value="IN_SCHOOL"
                    control={<Radio />}
                    label={t("iAmInSchool")}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: colors.outlineVariant,
                      marginRight: 0,
                      padding: "10px 0",
                    }}
                  />
                  <FormControlLabel
                    value="TRAININGPROGRAM"
                    control={<Radio />}
                    label={t("iAmInTraining")}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: colors.outlineVariant,
                      marginRight: 0,
                      padding: "10px 0",
                    }}
                  />
                  <FormControlLabel
                    value="OTHER"
                    control={<Radio />}
                    label={t("other")}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: colors.outlineVariant,
                      marginRight: 0,
                      padding: "10px 0",
                    }}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          {employmentStatusError && (
            <FormHelperText error>{employmentStatusError}</FormHelperText>
          )}
          {employmentStatus === "EMPLOYED" && (
            <Controller
              name="employer_name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("employerName")}
                  fullWidth
                  margin="normal"
                  error={!!employerNameError}
                  helperText={employerNameError}
                />
              )}
            />
          )}

          {employmentStatus === "OTHER" && (
            <Controller
              name="other_status_description"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("other")}
                  fullWidth
                  margin="normal"
                  error={!!otherStatusDescriptionError}
                  helperText={otherStatusDescriptionError}
                />
              )}
            />
          )}
        </form>
      </Stack>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton
            label={t("next")}
            onClick={handleNextClick}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default YourStatusForm;
