import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { reverse } from "utils/urls";
import { URLS } from "config/urls";

import FullPageDialog from "components/FullPageDialog";

import PrimaryContactForm from "entities/FamilySpecialist/PrimaryContactForm";
import { waitlistCreate, IPrimaryContactData } from "entities/FamilyEdit/sdk";
import Container from "components/Container";
import Stack from "components/Stack";

const WaitlistPrimaryContact = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSave = (data: Partial<IPrimaryContactData>) => {
    return waitlistCreate(data as IPrimaryContactData).then((response) => {
      navigate(
        reverse(URLS.FAMILY_SPECIALIST_YOUR_STATUS, { familyId: response.id })
      );
    });
  };

  return (
    <FullPageDialog
      title={t("primaryContact")}
      backUrl={URLS.FAMILY_SPECIALIST_AUTHORIZATION}
    >
      <Container
        maxWidth="lg"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            flex: 1,
            justifyContent: "space-between",
            padding: 2,
          }}
        >
          <PrimaryContactForm onSave={handleSave} />
        </Stack>
      </Container>
    </FullPageDialog>
  );
};

export default WaitlistPrimaryContact;
