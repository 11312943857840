import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Control, FieldErrors, useForm } from "react-hook-form";

import _ from "lodash";

import Container from "components/Container";
import Stack from "components/Stack";
import Text from "components/Text";
import CircularProgress from "components/CircularProgress";
import Box from "components/Box";
import BasicButton from "components/BasicButton";
import FileUploadBox from "components/FileUploadBox";

import { IFamily, IParentToIncomeEligibles } from "entities/Family/sdk";
import { IDocument, uploadAndParseDocument } from "entities/Documents/sdk";
import { incomeEligibleParentAutohrizationUpdate } from "entities/Authorization/sdk";

import { colors } from "theme";
import { UploadIcon, CheckIcon } from "icons";

import useIncomeEligibleFlowNavigation from "pages/VerifyIncomeFlow/hooks";
import Chip from "components/Chip";

interface IProps {
  header: React.ReactNode;
  FormComponent?: ({
    control,
    errors,
  }: {
    control: Control<any, any>;
    errors: FieldErrors<any>;
  }) => React.ReactNode;
  getDefaultValues?: (
    parentToIncomeEligibles?: IParentToIncomeEligibles
  ) => any;
  parentId: number;
  voucherId: number;
  documentParsingTypes: IDocument["parsing_types"];
  family: IFamily;
  reloadFamily: () => Promise<void | IFamily>;
  verificationFilter: (document: IDocument) => boolean;
  sumTargetField?: string;
  sumSourceField?: keyof IDocument;
  verificationChips?: Record<string, string>;
  fieldsMapping?: Partial<Record<keyof IDocument, string>>;
}

const ParseFile = ({
  header,
  FormComponent,
  getDefaultValues,
  parentId,
  voucherId,
  documentParsingTypes,
  family,
  reloadFamily,
  verificationFilter,
  sumTargetField,
  sumSourceField,
  verificationChips,
  fieldsMapping,
}: IProps) => {
  const { t } = useTranslation();
  const { navigateNext } = useIncomeEligibleFlowNavigation();
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const parent = family.family_members.find((member) => member.id === parentId);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const parentIncomeEligible = parent?.parent_to_income_eligibles.find(
    (e) => e.voucher.id === voucherId
  );

  const parentDocuments = useMemo(
    () =>
      family?.documents.filter(
        (document) =>
          document.parent?.id === parentId &&
          document.vouchers?.some((voucher) => voucher.id === voucherId)
      ) || [],
    [family, parentId, voucherId]
  );

  const uploadedParentDocuments = useMemo(
    () =>
      parentDocuments?.filter((document) =>
        document.parsing_types.some((parsing_type) =>
          documentParsingTypes.includes(parsing_type)
        )
      ) || [],
    [parentDocuments, documentParsingTypes]
  );

  const allUploadedParentDocumentsAreParsed = uploadedParentDocuments.every(
    (document) => document.is_ready
  );

  const verifiedDocuments = parentDocuments.filter(verificationFilter);

  const subjectDocuments = _.uniqBy(
    [...uploadedParentDocuments, ...verifiedDocuments],
    "id"
  );

  const subjectConfirmed = verifiedDocuments.length !== 0;

  const defaultFormValues = getDefaultValues
    ? getDefaultValues(parentIncomeEligible)
    : undefined;

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    watch,
  } = useForm({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (getDefaultValues && sumTargetField && sumSourceField) {
      const totalValue = _.sumBy(verifiedDocuments, (doc) =>
        _.get(doc, sumSourceField as string, 0)
      );

      const defaultValues = getDefaultValues(parentIncomeEligible);
      if (!defaultValues[sumTargetField] && totalValue !== 0) {
        setValue(sumTargetField, totalValue);
      }
    }
  }, [
    parentIncomeEligible,
    getDefaultValues,
    verifiedDocuments,
    setValue,
    sumTargetField,
    sumSourceField,
    watch,
  ]);

  const initialLoadRef = useRef(false);
  const processedDocsRef = useRef<Set<number>>(new Set());

  useEffect(() => {
    if (!initialLoadRef.current) {
      const verifiedIds = _.map(verifiedDocuments, "id");
      verifiedIds.forEach((id) => processedDocsRef.current.add(id));
      initialLoadRef.current = true;
    }
  }, [verifiedDocuments]);

  useEffect(() => {
    const newlyVerifiedDocs = _.filter(
      verifiedDocuments,
      (doc) => !processedDocsRef.current.has(doc.id)
    );

    _.forEach(newlyVerifiedDocs, (doc) => {
      _.forEach(fieldsMapping, (formField, docField) => {
        if (!formField) return;
        const docValue = doc[docField as keyof IDocument];
        if (
          docValue != null &&
          !dirtyFields[formField] &&
          !defaultFormValues?.[formField]
        ) {
          setValue(formField, docValue);
        }
      });
      processedDocsRef.current.add(doc.id);
    });
  }, [
    verifiedDocuments,
    fieldsMapping,
    dirtyFields,
    defaultFormValues,
    setValue,
  ]);

  useEffect(() => {
    if (!allUploadedParentDocumentsAreParsed) {
      const interval = setInterval(
        () =>
          reloadFamily().then(() => {
            if (allUploadedParentDocumentsAreParsed) {
              clearInterval(interval);
              reloadFamily();
            }
          }),
        2000
      );
      return () => clearInterval(interval);
    }
  }, [allUploadedParentDocumentsAreParsed, reloadFamily]);

  const handleFileSelect = (file: any) => {
    setIsUploading(true);
    uploadAndParseDocument({
      parentId,
      file,
      voucherId,
      parsingTypes: documentParsingTypes,
    }).then(() => {
      setIsUploading(false);
      reloadFamily();
    });
  };

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    const submitData = _.mapValues(data, (v) => (v === "" ? null : v));

    await incomeEligibleParentAutohrizationUpdate({
      authorizationId: voucherId,
      parentId,
      data: submitData,
    });
    const family = await reloadFamily();
    if (family) {
      setIsSubmitting(false);
      navigateNext({ family });
    }
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      handleFileSelect(file);
    }
  };

  const uploadAnotherFile = () => {
    fileInputRef.current?.click();
  };

  const getChipsForDocument = (
    document: IDocument,
    verificationChips: Record<string, string> | undefined,
    verifiedDocuments: IDocument[],
    t: (key: string) => string
  ): React.ReactNode[] => {
    const chips: React.ReactNode[] = [];

    if (verificationChips) {
      for (const [field, translationKey] of Object.entries(verificationChips)) {
        if ((document as any)[field]) {
          chips.push(
            <Chip
              key={field}
              icon={<CheckIcon />}
              label={t(translationKey)}
              sx={{ backgroundColor: colors.secondaryContainer, mr: 1 }}
            />
          );
        }
      }
    }

    if (chips.length === 0) {
      const isVerified = verifiedDocuments.includes(document);
      chips.push(
        <Chip
          key="default-file"
          icon={isVerified ? <CheckIcon /> : undefined}
          label={document.file.original_file_name}
          sx={isVerified ? { backgroundColor: colors.secondaryContainer } : {}}
        />
      );
    }

    return chips;
  };

  return (
    <Stack
      sx={{
        justifyContent: "space-between",
        flex: 1,
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          margin: "30px auto",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Stack spacing={2}>
          {header}
          {isUploading && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress
                thickness={8}
                size={48}
                sx={{ color: "#DA2C52" }}
              />
            </div>
          )}
          {!allUploadedParentDocumentsAreParsed &&
            !subjectConfirmed &&
            !isUploading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CircularProgress
                  thickness={8}
                  size={48}
                  sx={{ color: "#DA2C52" }}
                />
                <Text variant="labelLarge" sx={{ marginTop: "16px" }}>
                  {t("verifyingDocuments")}
                </Text>
              </div>
            )}

          {subjectDocuments.length === 0 && (
            <FileUploadBox file={null} setFile={handleFileSelect} />
          )}
          <Box
            flexDirection="column"
            sx={{
              padding: (theme) => theme.spacing(2, 0),
              borderBottom: "1px solid #CFC8C3",
            }}
          >
            {subjectDocuments.map((document) => {
              const chips = getChipsForDocument(
                document,
                verificationChips,
                verifiedDocuments,
                t
              );
              return (
                <Box key={document.id} textAlign="center" mb={2}>
                  {chips}
                </Box>
              );
            })}
          </Box>
        </Stack>

        {FormComponent && <FormComponent control={control} errors={errors} />}
      </Container>
      <Stack
        gap={2}
        sx={{
          borderTop: "1px solid " + colors.outline,
          padding: (theme) => theme.spacing(4, 1),
          alignItems: "center",
        }}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={onFileChange}
        />
        {subjectDocuments.length > 0 && (
          <BasicButton
            label={t("uploadAnotherFile")}
            onClick={uploadAnotherFile}
            backgroundColor={colors.secondaryContainer}
            color={colors.onSecondaryContainer}
            onHoverBackground={colors.onHoverSecondaryContainer}
            startIcon={<UploadIcon />}
          />
        )}
        {FormComponent ? (
          <BasicButton
            label={t("next")}
            onClick={handleSubmit(onSubmit)}
            isDisabled={isSubmitting}
          />
        ) : (
          <BasicButton
            label={t("next")}
            onClick={() => {
              navigateNext({ family });
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ParseFile;
