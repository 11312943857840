import Text from "components/Text";
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "components/Dialog";
import BasicButton from "components/BasicButton";

import theme, { colors } from "theme";

interface IProps {
  open: boolean;
  title?: string;
  description?: string;
  onClose: () => void;
  onConfirm: () => void;
}

const CloseDialog = ({
  open,
  title = "Are you sure?",
  description = "You have unsaved changes. If you close, the data will not be saved.",
  onClose,
  onConfirm,
}: IProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          backgroundColor: colors.surfaceContainerLow,
          padding: theme.spacing(1, 2),
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Text>{description}</Text>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", mb: theme.spacing(2) }}>
        <BasicButton onClick={onClose} label="Cancel" />
        <BasicButton onClick={onConfirm} label="Confirm" />
      </DialogActions>
    </Dialog>
  );
};

export default CloseDialog;
