import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FullPageDialog from "components/FullPageDialog";
import Container from "components/Container";
import Stack from "components/Stack";

import { reverse } from "utils/urls";
import { URLS } from "config/urls";

import { useFamilySpecialistParents } from "entities/FamilyEdit/sdk";
import ParentsListForm from "entities/Parent/components/ParentsListForm";

const FamilySpecialistIncomeEligibleParentsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { familyId } = useParams<{ familyId: string }>();
  const familyIdNumber = familyId ? parseInt(familyId) : undefined;
  if (!familyIdNumber) {
    return null;
  }

  const { data: parentsData, error } =
    useFamilySpecialistParents(familyIdNumber);

  const handleNext = () => {
    navigate(
      reverse(URLS.FAMILY_SPECIALIST_INCOME_ELIGIBLE_CHILDREN, {
        familyId: familyIdNumber,
      })
    );
  };

  const handleAddParent = () => {
    navigate(
      reverse(URLS.FAMILY_SPECIALIST_INCOME_ELIGIBLE_ADD_PARENT, {
        familyId: familyIdNumber,
      })
    );
  };

  return (
    <FullPageDialog
      title={t("parents")}
      closeUrl={reverse(URLS.FAMILY_SPECIALIST_FAMILY_DETAIL, {
        id: familyIdNumber,
      })}
    >
      <Container
        maxWidth="lg"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            flex: 1,
            justifyContent: "space-between",
            padding: 2,
          }}
        >
          {!parentsData ? null : (
            <ParentsListForm
              parents={parentsData}
              onNext={handleNext}
              onAddParent={handleAddParent}
              errorLoadingData={!!error}
            />
          )}
        </Stack>
      </Container>
    </FullPageDialog>
  );
};

export default FamilySpecialistIncomeEligibleParentsList;
