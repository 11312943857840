import { useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import FullPageDialog from "components/FullPageDialog";
import Container from "components/Container";
import Stack from "components/Stack";

import { IAddressUpdate } from "entities/FamilyMember/sdk";
import { familySpecialistAddressUpdate } from "entities/FamilyEdit/sdk";
import MailingAddressForm from "entities/Family/components/MailingAddressForm";

import { reverse } from "utils/urls";
import { URLS } from "config/urls";
import { useFamilyDetail } from "entities/FamilySpecialist/sdk";

const FamilySpecialistMailingAddress = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { familyId } = useParams<{ familyId: string }>();
  const familyIdNumber = familyId ? parseInt(familyId) : undefined;

  const { data: familyData } = useFamilyDetail(familyIdNumber);

  if (!familyIdNumber || !familyData) {
    return null;
  }

  const handleSave = (data: IAddressUpdate) => {
    return familySpecialistAddressUpdate(data, familyIdNumber).then(() => {
      navigate(
        reverse(URLS.FAMILY_SPECIALIST_CHILDREN, { familyId: familyIdNumber })
      );
    });
  };

  return (
    <FullPageDialog
      title={t("mailingAddress")}
      backUrl={reverse(URLS.FAMILY_SPECIALIST_PARENTS, {
        familyId: familyIdNumber,
      })}
    >
      <Container
        maxWidth="lg"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            flex: 1,
            justifyContent: "space-between",
            padding: 2,
          }}
        >
          <MailingAddressForm onSave={handleSave} initialData={familyData} />
        </Stack>
      </Container>
    </FullPageDialog>
  );
};

export default FamilySpecialistMailingAddress;
