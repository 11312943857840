import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import FullPageDialog from "components/FullPageDialog";

import { useMyFamily } from "entities/Family/sdk";
import useIncomeEligibleFlowNavigation from "../hooks";
import { URLS } from "config/urls";
import OtherIncomeForm from "entities/Authorization/components/OtherIncomeForm";
import { incomeEligibleParentAutohrizationUpdate } from "entities/Authorization/sdk";

const OtherIncomePage = () => {
  const { t } = useTranslation();

  const { data: familyData, mutate: reloadFamily } = useMyFamily();
  const { voucherId: vouchersIdParam, parentId: parentIdParam } = useParams<{
    voucherId: string;
    parentId: string;
  }>();
  const authorizationId = vouchersIdParam
    ? parseInt(vouchersIdParam)
    : undefined;
  const parentId = parentIdParam ? parseInt(parentIdParam) : undefined;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { getBackUrl, navigateNext } = useIncomeEligibleFlowNavigation();

  const parent = familyData?.family_members.find(
    (member) => member.id === parentId
  );

  const parentIncomeEligible = parent?.parent_to_income_eligibles.find(
    (e) => e.voucher.id === authorizationId
  );

  const defaultValues = {
    has_rental_income: parentIncomeEligible?.has_rental_income || false,
    has_lottery_earnings: parentIncomeEligible?.has_lottery_earnings || false,
    has_alimony: parentIncomeEligible?.has_alimony || false,
    has_workers_compensation:
      parentIncomeEligible?.has_workers_compensation || false,
    has_unemployment_benefits:
      parentIncomeEligible?.has_unemployment_benefits || false,
    has_retirement_income: parentIncomeEligible?.has_retirement_income || false,
  };

  if (!parent || !authorizationId || !parentId || !familyData) {
    return null;
  }

  const onSave = async (data: typeof defaultValues) => {
    setIsSubmitting(true);
    await incomeEligibleParentAutohrizationUpdate({
      authorizationId,
      parentId,
      data,
    });
    const family = await reloadFamily();
    if (family) {
      setIsSubmitting(false);
      navigateNext({ family });
    }
  };

  return (
    <FullPageDialog
      title={t("otherIncome")}
      backUrl={getBackUrl({ family: familyData })}
      closeUrl={URLS.HOME}
    >
      <OtherIncomeForm
        defaultValues={defaultValues}
        onSave={onSave}
        parent={parent}
        isButtonDisabled={isSubmitting}
      />
    </FullPageDialog>
  );
};

export default OtherIncomePage;
