import { useTranslation } from "react-i18next";
import { useState } from "react";
import Stack from "components/Stack";
import Text from "components/Text";
import AddButton from "components/AddButton";
import BasicButton from "components/BasicButton";
import FormHelperText from "components/FormHelperText";
import useMediaQuery from "components/useMediaQuery";
import { colors } from "theme";

interface IChildData {
  firstName: string;
  lastName: string;
  age: number;
}

interface IChildrenListProps {
  childrenData: IChildData[];
  onAddChild: () => void;
  onNext: () => void;
  errorLoadingData?: boolean;
  hasValidation?: boolean;
}

const ChildrenList = ({
  childrenData,
  onAddChild,
  onNext,
  errorLoadingData = false,
  hasValidation = false,
}: IChildrenListProps) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");
  const [showError, setShowError] = useState(false);

  const handleNext = () => {
    if (childrenData.length === 0 && hasValidation) {
      setShowError(true);
    } else {
      setShowError(false);
      onNext();
    }
  };

  return (
    <>
      <Stack
        sx={{ mt: "30px", width: matches ? "50%" : "auto" }}
        alignSelf="center"
        alignItems="center"
      >
        <Text variant="titleMedium" sx={{ mt: 2, mb: 2 }}>
          {t("childrenHeader")}
        </Text>
        {errorLoadingData && <Text color="error">{t("errorLoadingData")}</Text>}
        <Stack
          sx={{
            mb: "20px",
            borderBottom: "1px solid",
            borderColor: colors.outline,
            width: "100%",
          }}
        >
          {childrenData.map((child, index) => (
            <Stack
              key={index}
              sx={{
                py: 2,
                borderTop: "1px solid",
                borderColor: colors.outline,
              }}
              direction="row"
              justifyContent="space-between"
            >
              <Text variant="titleMedium">
                {child.firstName} {child.lastName}
              </Text>
              <Text variant="titleMedium">{child.age} yrs</Text>
            </Stack>
          ))}
        </Stack>
        <AddButton
          label={t("addChild")}
          backgroundColor={colors.secondaryContainer}
          onClick={onAddChild}
        />
        {showError && (
          <FormHelperText error sx={{ mt: "20px" }}>
            {t("youMustAddAtLeastOneChild")}
          </FormHelperText>
        )}
      </Stack>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton label={t("next")} onClick={handleNext} />
        </Stack>
      </Stack>
    </>
  );
};

export default ChildrenList;
