import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import Stack from "components/Stack";
import TextField from "components/TextField";
import BasicButton from "components/BasicButton";
import Box from "components/Box";
import FormLabel from "components/FormLabel";
import Text from "components/Text";
import useMediaQuery from "components/useMediaQuery";

import { colors } from "theme";

export interface IAdditionalInformationFormData {
  additional_information: string;
}

interface IAdditionalInformationFormProps {
  onSave: (data: IAdditionalInformationFormData) => Promise<void>;
  initialData?: Partial<IAdditionalInformationFormData>;
}

const AdditionalInformationForm = ({
  onSave,
  initialData = {},
}: IAdditionalInformationFormProps) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");
  const { control, handleSubmit } = useForm<IAdditionalInformationFormData>({
    defaultValues: {
      additional_information: initialData.additional_information || "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (data: IAdditionalInformationFormData) => {
    setIsSubmitting(true);
    onSave(data)
      .catch(() => {
        toast.error(t("errorSavingData"));
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Stack alignSelf="center" sx={{ width: matches ? "50%" : "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            m: "10px 0",
          }}
        >
          <Box sx={{ flexGrow: 1, height: "1px", bgcolor: "text.primary" }} />
          <FormLabel component="legend" style={{ padding: "0 10px" }}>
            <Text variant="titleMedium" color={colors.onSurface}>
              {t("optional")}
            </Text>
          </FormLabel>
          <Box sx={{ flexGrow: 1, height: "1px", bgcolor: "text.primary" }} />
        </Box>
        <Controller
          name="additional_information"
          control={control}
          render={({ field }) => (
            <TextField
              multiline
              rows={2}
              variant="outlined"
              fullWidth
              placeholder={t("anythingElse")}
              {...field}
            />
          )}
        />
      </Stack>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{ textAlign: "right", mt: "30px" }}
        >
          <BasicButton
            label={t("next")}
            onClick={handleSubmit(onSubmit)}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default AdditionalInformationForm;
