import { useTranslation } from "react-i18next";
import { useState } from "react";
import Stack from "components/Stack";
import Text from "components/Text";
import AddButton from "components/AddButton";
import BasicButton from "components/BasicButton";
import FormHelperText from "components/FormHelperText";
import useMediaQuery from "components/useMediaQuery";
import { colors } from "theme";
import {
  IParentsFamilyMember,
  getEmploymentStatusDisplayValue,
} from "entities/FamilyMember/sdk";
import { IParents } from "entities/FamilyEdit/sdk";

interface IParentsListProps {
  parents: IParents | IParentsFamilyMember;
  onNext: () => void;
  onAddParent: () => void;
  errorLoadingData?: boolean;
}

const ParentsListForm = ({
  parents,
  onNext,
  onAddParent,
  errorLoadingData,
}: IParentsListProps) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");
  const [showError, setShowError] = useState(false);

  const handleNext = () => {
    if (parents.family_members.length === 0) {
      setShowError(true);
    } else {
      setShowError(false);
      onNext();
    }
  };

  return (
    <>
      <Stack
        sx={{ mt: "30px", width: matches ? "50%" : "100%" }}
        alignSelf="center"
        alignItems="center"
      >
        {errorLoadingData && <Text color="error">{t("errorLoadingData")}</Text>}
        <Stack
          sx={{
            mb: "20px",
            borderBottom: "1px solid",
            borderColor: colors.outline,
            width: "100%",
          }}
        >
          {parents.family_members.map((parent, index) => (
            <Stack
              key={index}
              sx={{
                py: 2,
                borderTop: "1px solid",
                borderColor: colors.outline,
              }}
              direction="row"
              justifyContent="space-between"
            >
              <Text variant="titleMedium">
                {!parent.first_name && !parent.last_name && "Unknown name"}
                {parent.first_name} {parent.last_name}
              </Text>
              <Text variant="titleMedium">
                <Text variant="titleMedium" fontWeight="bold">
                  {parent.employment_status &&
                    getEmploymentStatusDisplayValue(parent.employment_status)}
                </Text>{" "}
                {parent.employer_name} {parent.other_status_description}
              </Text>
            </Stack>
          ))}
        </Stack>
        <AddButton
          label={t("addParent")}
          backgroundColor={colors.secondaryContainer}
          onClick={onAddParent}
        />
        {showError && (
          <FormHelperText error sx={{ mt: "20px" }}>
            {t("youMustAddAtLeastOneChild")}
          </FormHelperText>
        )}
      </Stack>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton label={t("next")} onClick={handleNext} />
        </Stack>
      </Stack>
    </>
  );
};

export default ParentsListForm;
