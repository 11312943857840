import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import FullPageDialog from "components/FullPageDialog";
import Container from "components/Container";

import { colors } from "theme";

import CompleteImage from "assets/complete.png";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const FamilySpecialistComplete = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { familyId: familyIdParam } = useParams<{ familyId: string }>();
  if (!familyIdParam) return null;

  const familyId = parseInt(familyIdParam);

  const goToHomePage = () => {
    navigate(
      reverse(URLS.FAMILY_SPECIALIST_FAMILY_DETAIL, {
        id: familyId.toString(),
      })
    );
  };
  return (
    <FullPageDialog title={t("complete")}>
      <Container
        maxWidth="lg"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Stack
          justifyContent={"center"}
          gap="30px"
          sx={{ marginTop: "50px" }}
          alignItems="center"
        >
          <img
            src={CompleteImage}
            alt="Completed"
            style={{ maxWidth: "400px" }}
          />
          <Text
            variant="titleLarge"
            sx={{ textAlign: "center", marginBottom: "50px" }}
          >
            {t("completeHeader")}
          </Text>
          <BasicButton
            label={t("viewFamilyDetail")}
            backgroundColor={colors.secondaryContainer}
            color={colors.onSecondaryContainer}
            onHoverBackground={colors.onHoverSecondaryContainer}
            onClick={goToHomePage}
          />
        </Stack>
      </Container>
    </FullPageDialog>
  );
};

export default FamilySpecialistComplete;
