import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import useIncomeEligibleFlowNavigation from "../hooks";

import Text from "components/Text";
import Stack from "components/Stack";
import Container from "components/Container";
import FullPageDialog from "components/FullPageDialog";
import BasicButton from "components/BasicButton";
import TextField from "components/TextField";

import { useMyFamily } from "entities/Family/sdk";
import { URLS } from "config/urls";
import { colors } from "theme";
import { incomeEligibleParentAutohrizationUpdate } from "entities/Authorization/sdk";

interface FormValues {
  hours_per_day_seeking_work_or_training?: number;
}

const SeekingActivityPage = () => {
  const { t } = useTranslation();
  const { getBackUrl, navigateNext } = useIncomeEligibleFlowNavigation();
  const { parentId: parentIdParam, voucherId: vouchersIdParam } = useParams<{
    parentId: string;
    voucherId: string;
  }>();
  const { data: family, mutate } = useMyFamily();
  const parentId = parseInt(String(parentIdParam));
  const voucherId = parseInt(String(vouchersIdParam));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const parent = family?.family_members?.find(
    (parent) => parent.id === parentId
  );
  const parentIncomeEligible = parent?.parent_to_income_eligibles.find(
    (e) => e.voucher.id === voucherId
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      hours_per_day_seeking_work_or_training: undefined,
    },
  });

  useEffect(() => {
    if (
      parentIncomeEligible?.hours_per_day_seeking_work_or_training !== undefined
    ) {
      reset({
        hours_per_day_seeking_work_or_training:
          parentIncomeEligible.hours_per_day_seeking_work_or_training,
      });
    }
  }, [parentIncomeEligible, reset]);

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    incomeEligibleParentAutohrizationUpdate({
      authorizationId: voucherId,
      parentId,
      data,
    });
    const family = await mutate();
    if (family) {
      setIsSubmitting(false);
      navigateNext({ family });
    }
  };

  if (!parentId || !voucherId || !family || !parent) {
    return null;
  }

  return (
    <FullPageDialog
      title={t("seekingActivity")}
      backUrl={getBackUrl({ family })}
      closeUrl={URLS.HOME}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Stack spacing={2}>
            <Text>
              {t("hoursQuestion", {
                parentName: `${parent.first_name} ${parent.last_name}`,
              })}
            </Text>
          </Stack>
          <Controller
            name="hours_per_day_seeking_work_or_training"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("hoursPerWeekLabel")}
                fullWidth
                margin="normal"
                type="number"
                error={!!errors.hours_per_day_seeking_work_or_training}
                onChange={(e) => {
                  const value = e.target.value;
                  field.onChange(value === "" ? null : Number(value));
                }}
              />
            )}
          />
        </Container>
        <Stack
          gap={2}
          sx={{
            borderTop: "1px solid " + colors.outline,
            padding: (theme) => theme.spacing(4, 1),
            alignItems: "center",
          }}
        >
          <BasicButton
            label={t("next")}
            onClick={handleSubmit(onSubmit)}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </FullPageDialog>
  );
};

export default SeekingActivityPage;
