import { useTranslation } from "react-i18next";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import Stack from "components/Stack";
import Text from "components/Text";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";

import theme, { colors } from "theme";
import Container from "components/Container";
import BasicButton from "components/BasicButton";

interface IDefaultValues {
  military: {
    parent: number;
    is_military_retired: boolean;
    is_in_active_military_duty: boolean;
  }[];
}

interface IProps {
  defaultValues: IDefaultValues;
  parentById: { [key: number]: { first_name: string; last_name: string } };
  onSave: (data: IDefaultValues) => void;
  isButtonDisabled?: boolean;
}

const MilitaryOrRetiredForm = ({
  defaultValues,
  parentById,
  onSave,
  isButtonDisabled = false,
}: IProps) => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues,
  });

  const { fields } = useFieldArray({
    control,
    name: "military",
  });

  return (
    <>
      <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
        <div style={{ margin: theme.spacing(2, 0) }}>
          <Text
            component="p"
            variant="titleMedium"
            color={colors.onSurface}
            sx={{ mb: theme.spacing(2) }}
          >
            {t("retiredOrActiveDutyQuestion")}
          </Text>
        </div>
        <Stack gap={theme.spacing(2)} flex={1} sx={{ mt: theme.spacing(4) }}>
          {fields.map((field, index) => (
            <Stack gap={theme.spacing(2)} key={index}>
              <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                {`${parentById[field.parent].first_name} ${parentById[field.parent].last_name}`.trim() ===
                  "" && t("unknownName")}
                {parentById[field.parent].first_name}{" "}
                {parentById[field.parent].last_name}
              </Text>

              <Controller
                name={`military.${index}.is_military_retired`}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("parentRetiredStatus")}
                  />
                )}
              />
              <Controller
                name={`military.${index}.is_in_active_military_duty`}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("parentActiveDutyStatus")}
                  />
                )}
              />
            </Stack>
          ))}
        </Stack>
      </Container>
      <div
        style={{
          padding: theme.spacing(2, 3),
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <BasicButton
          label={t("next")}
          onClick={handleSubmit(onSave)}
          isDisabled={isButtonDisabled}
        />
      </div>
    </>
  );
};

export default MilitaryOrRetiredForm;
