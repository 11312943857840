import { useNavigate } from "react-router-dom";

import { URLS } from "config/urls";

import {
  IEmploymentStatusUpdate,
  employmentStatusUpdate,
} from "entities/FamilyMember/sdk";
import YourStatusForm from "entities/Family/components/YourStatusForm";
import { useMyFamily } from "entities/Family/sdk";

const YourStatus = () => {
  const navigate = useNavigate();
  const { data: family } = useMyFamily();

  const handleSave = (data: IEmploymentStatusUpdate) => {
    return employmentStatusUpdate(data).then(() => {
      navigate(URLS.FAMILY_INFORMATION_PARENTS);
    });
  };

  return (
    <YourStatusForm
      onSave={handleSave}
      hasValidation={true}
      initialData={family}
    />
  );
};

export default YourStatus;
