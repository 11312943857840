import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

import Stack from "components/Stack";
import TextField from "components/TextField";
import BasicButton from "components/BasicButton";
import useMediaQuery from "components/useMediaQuery";

import { colors } from "theme";

import { IAddressUpdate } from "entities/FamilyMember/sdk";
import { IFamilyDetail } from "entities/FamilySpecialist/sdk";
import { IFamily } from "entities/Family/sdk";

interface MailingAddressFormProps {
  onSave: (data: IAddressUpdate) => Promise<void>;
  hasValidation?: boolean;
  initialData?: IFamilyDetail | IFamily;
}

const MailingAddressForm = ({
  onSave,
  hasValidation = false,
  initialData,
}: MailingAddressFormProps) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [streetAddressError, setStreetAddressError] = useState<string | null>(
    null
  );
  const [cityError, setCityError] = useState<string | null>(null);
  const [zipCodeError, setZipCodeError] = useState<string | null>(null);

  const { control, watch, reset } = useForm<IAddressUpdate>({
    defaultValues: {
      street_address: initialData?.street_address || "",
      unit_address: initialData?.unit_address || "",
      city: initialData?.city || "",
      zip_code: initialData?.zip_code || "",
    },
  });

  useEffect(() => {
    if (initialData) {
      reset({
        street_address: initialData.street_address || "",
        unit_address: initialData.unit_address || "",
        city: initialData.city || "",
        zip_code: initialData.zip_code || "",
      });
    }
  }, [initialData, reset]);

  const watchAllFields = watch();

  const handleNextClick = () => {
    let valid = true;

    if (hasValidation) {
      if (!watchAllFields.street_address) {
        setStreetAddressError(t("fieldRequired"));
        valid = false;
      } else {
        setStreetAddressError(null);
      }

      if (!watchAllFields.city) {
        setCityError(t("fieldRequired"));
        valid = false;
      } else {
        setCityError(null);
      }

      if (!watchAllFields.zip_code) {
        setZipCodeError(t("fieldRequired"));
        valid = false;
      } else if (!/^\d{5}(?:[-\s]\d{4})?$/.test(watchAllFields.zip_code)) {
        setZipCodeError(t("invalidZipCode"));
        valid = false;
      } else {
        setZipCodeError(null);
      }
    }

    if (valid) {
      setIsSubmitting(true);

      const data: IAddressUpdate = {
        street_address: watchAllFields.street_address,
        unit_address: watchAllFields.unit_address,
        city: watchAllFields.city,
        zip_code: watchAllFields.zip_code,
      };

      onSave(data)
        .catch((error) => {
          toast.error(error?.message ?? t("errorSavingData"));
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <>
      <Stack alignItems="center">
        <form style={{ width: matches ? "50%" : "auto" }}>
          <Controller
            name="street_address"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("addressLine1")}
                fullWidth
                margin="normal"
                placeholder={t("streetName")}
                error={!!streetAddressError}
                helperText={streetAddressError}
              />
            )}
          />

          <Controller
            name="unit_address"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("addressLine2")}
                fullWidth
                margin="normal"
                placeholder={t("addressInfo")}
              />
            )}
          />

          <Stack
            sx={{
              flexDirection: matches ? "row" : "column",
              gap: matches ? "20px" : 0,
            }}
          >
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("city")}
                  fullWidth
                  margin="normal"
                  error={!!cityError}
                  helperText={cityError}
                />
              )}
            />
            <Controller
              name="zip_code"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("zipCode")}
                  fullWidth
                  margin="normal"
                  error={!!zipCodeError}
                  helperText={zipCodeError}
                />
              )}
            />
          </Stack>
        </form>
      </Stack>

      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton
            label={t("next")}
            onClick={handleNextClick}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default MailingAddressForm;
