import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import StyledSelect from "components/StyledSelect";
import useMediaQuery from "components/useMediaQuery";

import { URLS } from "config/urls";
import { getDefaultLanguageValue } from "utils/language";

import {
  IPersonalInformationUpdate,
  useGenderOptions,
  useLanguageOptions,
  personalInformationUpdate,
} from "entities/FamilyMember/sdk";

import { colors } from "theme";
import { useMyFamily } from "entities/Family/sdk";

const AboutYou = () => {
  const { t, i18n } = useTranslation();
  const defaultLanguage = getDefaultLanguageValue(i18n.language);
  const matches = useMediaQuery("(min-width:600px)");
  const { data: family } = useMyFamily();

  const genderOptions = useGenderOptions();
  const languageOptions = useLanguageOptions();

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [genderError, setGenderError] = useState<string | null>(null);
  const [languageError, setLanguageError] = useState<string | null>(null);

  const { control, watch } = useForm<IPersonalInformationUpdate>({
    defaultValues: {
      gender: family?.creator.gender || undefined,
      preferred_language: family?.creator.preferred_language || defaultLanguage,
    },
  });

  const genderData = watch("gender");
  const languageData = watch("preferred_language");

  const handleNextClick = () => {
    let valid = true;
    if (!genderData) {
      setGenderError(t("fieldRequired"));
      valid = false;
    } else {
      setGenderError(null);
    }

    if (!languageData) {
      setLanguageError(t("fieldRequired"));
      valid = false;
    } else {
      setLanguageError(null);
    }

    if (valid) {
      setIsSubmitting(true);
      const data = {
        gender: genderData,
        preferred_language: languageData,
      };

      personalInformationUpdate(data)
        .then(() => {
          navigate(URLS.FAMILY_INFORMATION_YOUR_STATUS);
          setIsSubmitting(false);
        })
        .catch(() => {
          const errorMessage = t("errorSavingData");
          toast.error(errorMessage);
          setIsSubmitting(false);
        });
    }
  };

  return (
    <>
      <Stack alignItems="center">
        <Text variant="titleMedium" sx={{ m: "10px 0" }}>
          {t("aboutYouHeader")}
        </Text>
        <form style={{ width: matches ? "400px" : "100%" }}>
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("gender")}
                options={genderOptions}
                fullWidth
                margin="normal"
                error={!!genderError}
                helperText={genderError}
              />
            )}
          />

          <Controller
            name="preferred_language"
            control={control}
            defaultValue={defaultLanguage}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("preferredLanguage")}
                options={languageOptions}
                fullWidth
                margin="normal"
                error={!!languageError}
                helperText={languageError}
              />
            )}
          />
        </form>
      </Stack>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton
            label={t("next")}
            onClick={handleNextClick}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default AboutYou;
